<template>
  <div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>

    <div class="content-wrapper container-xxl p-0 font">
      <banner
        title="staff PROFILE"
        :breadcrumb="[
          {
            label: 'staff',
          },
          { label: 'staff Profile' },
        ]"
      >
      </banner>
      <div v-if="loading == true">
        <Spinner />
      </div>
      <div class="row font" style="margin-top: -45px">
        <div class="col-xl-5 col-md-5 col-12" style="padding:5px 5px 5px 5px">
          <div
            class="card custom-card"
            style="
              height: 100%;
          
            "
          >
            <div class="card-body pt-2">
              <div class="row">
                <div class="col">
                  <h4 class="d-xl-none d-md-none d-block text-center">
                    <b style="color: #00364f; font-weight: bolder">staff Profile</b>
                  </h4>
                  <h4 class="d-none d-xl-block d-md-block text-start">
                    <b style="color: #00364f; font-weight: bolder">staff Profile</b>
                  </h4>
                </div>
              </div>

              <div class="row">
                <!-- img responsive mobile  -->
                <div
                  class="col-sm-12 col-12 d-block d-md-none d-xl-none d-lg-none text-center mb-1"
                >
                  <img
                    style="width: 100px; height: 100px; border-radius: 15px"
                    :src="profileImg"
                    alt=""
                  />
                  <br />
                  <span style="color: #00364f">
                    <p
                      v-if="staffinfo.loginStatus == 'true'"
                      class="btn btn-sm"
                      style="
                        width: 100px;
                        background-color: green;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        padding-left: 7px;
                        padding-right: 7px;
                        margin-top: 5px;
                        margin-bottom: 5px;
                      "
                    >
                      <b>Active</b>
                    </p>
                    <p
                      v-else
                      class="btn btn-sm"
                      style="
                        width: 30%;
                        background-color: red;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        padding-left: 7px;
                        padding-right: 7px;
                        margin-top: 5px;
                        margin-bottom: 5px;
                      "
                    >
                      <b>InActive</b>
                    </p>
                  </span>
                </div>
                <h5 class="text-start">
                  <b style="color: #f21300; font-weight: bolder">Personal Details </b>
                </h5>

                <div class="col-xl-9 col-md-9 col-lg-9 col-sm-12">
                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>Code : </b>
                    <span v-if="staffinfo">{{ staffinfo.code }} </span>
                  </div>

                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>Name : </b>
                    <span v-if="staffinfo">{{ staffinfo.name }} </span>
                  </div>

                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>DOB : </b>
                    <span v-if="staffinfo">{{ dateTime(staffinfo.dob) }}</span>
                  </div>

                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>Gender : </b>
                    <span v-if="staffinfo" style="text-transform: capitalize"
                      >{{ staffinfo.gender }}
                    </span>
                  </div>

                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>Phone Number : </b>
                    <span v-if="staffinfo">+91-{{ staffinfo.phone }}</span>
                  </div>

                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>Alt Phone Number : </b>
                    <span v-if="staffinfo">+91-{{ staffinfo.alternativePhone }}</span>
                  </div>

                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>Email Id : </b>
                    <span v-if="staffinfo">{{ staffinfo.email }}</span>
                  </div>
                </div>
                <!-- img responsive Pc  -->
                <div
                  class="col-xl-3 col-md-3 col-lg-3 d-none d-xl-block d-md-block d-lg-block text-center"
                >
                  <img
                    style="width: 100%; border-radius: 15px; margin-top: -20px"
                    :src="profileImg"
                    alt=""
                  />
                  <span style="color: #00364f; width: 40%">
                    <p
                      v-if="staffinfo.loginStatus == 'true'"
                      class="btn btn-sm"
                      style="
                        width: 100%;
                        background-color: green;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        padding-left: 7px;
                        padding-right: 7px;
                        margin-top: 5px;
                        margin-bottom: 5px;
                      "
                    >
                      <b>Active</b>
                    </p>
                    <p
                      v-else
                      class="btn btn-sm"
                      style="
                        width: 100%;
                        background-color: red;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        padding-left: 7px;
                        padding-right: 7px;
                        margin-top: 5px;
                        margin-bottom: 5px;
                      "
                    >
                      <b>InActive</b>
                    </p>
                  </span>
                </div>
              </div>
              <div class="col-12" style="margin-left: 7px">
                <div class="text">
                  <img
                    style="width: 8px; margin-right: 5px"
                    src="/assets/image/record.png"
                    alt=""
                  />
                  <b>Address : </b>
                  <span v-if="staffinfo">{{ staffinfo.address }}  {{ staffinfo.city }}  {{ staffinfo.pin }} </span>
                </div>
                <div class="text">
                  <img
                    style="width: 8px; margin-right: 5px"
                    src="/assets/image/record.png"
                    alt=""
                  />
                  <b>State : </b>
                  <span v-if="staffinfo.state">{{ staffinfo.state.name }}</span>
                </div>
              </div>
              <!-- bussiness Detais  -->
              <div class="row">
                <h5 class="text-start mt-2">
                  <b style="color: #f21300; font-weight: bolder">Kyc Details </b>
                </h5>
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>PAN : </b>
                    <span v-if="staffinfo.staff_kyc"
                      >{{ staffinfo.staff_kyc.panNo }}
                    </span>
                  </div>
                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>Aadhaar Number : </b>
                    <span v-if="staffinfo.staff_kyc"
                      >{{ staffinfo.staff_kyc.aadharNo }}
                    </span>
                  </div>
                  <!-- <div class="text">
                                            <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png"
                                                alt="" />
                                            <b>Account Number : </b>
                                            <span v-if="staffinfo.staff_kyc">{{ staffinfo.staff_kyc.accountNo }} </span>
                                        </div> -->
                </div>
              </div>
              <!-- end  bussiness Detais  -->

              <!-- Qualifications -->
              <div class="row">
                <h5 class="text-start mt-2">
                  <b style="color: #f21300; font-weight: bolder">Designation </b>
                </h5>
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <span v-if="staffinfo.designation"
                      >{{ staffinfo.designation.name }}
                    </span>
                  </div>
                </div>
              </div>

              <hr />
              <div class="row mt-1">
                <div class="col-12 text-end">
                  <button
                    @click="valueAssingForProfile()"
                    type="button"
                    class="btn text-white btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#profileUpdateModel"
                    style="background-color: #00364f; margin-right: 5px"
                  >
                    <b
                      >Edit Profile &nbsp;
                      <i class="fa fa-edit"></i>
                    </b>
                  </button>
                  <button
                  @click="valueAssingForProfile()"
                    type="button"
                    class="btn text-white btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#doccuments"
                    style="background-color: #f21300; margin-right: 5px"
                  >
                    <b
                      >Kyc Doccuments &nbsp;
                      <i class="fa fa-hand-pointer-o"></i>
                    </b>
                  </button>
                  <!-- <button type="button" class="btn btns text-white btn-sm"><b>Edit Profile &nbsp;
                        <font-awesome-icon icon="edit" />
                      </b></button> -->
                </div>
              </div>
              <div
                class="row mt-1"
                style="background-color: #e3e3e3; border-radius: 15px"
              >
                <div class="col-12 p-1">
                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>IP Address: </b>
                    <span v-if="staffinfo">{{ staffinfo.ipAddress }}</span>
                  </div>
                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>Last Login: </b>
                    <span v-if="staffinfo">{{ dateTimeWithTime(staffinfo.last_login) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->

  <div class="sidenav-overlay"></div>
  <div class="drag-target"></div>

  <!-- Modal kyc doccuments show  -->
  <div
    class="modal fade"
    id="doccuments"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel"><b>Kyc Doccuments</b></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid table-scroll">
            <table class="table table-hover table-sm text">
              <tr class="table-wrapper-scroll-y tr-head rounded-circle">
                <th>+</th>
                <th>Name</th>
                <th>Action</th>
              </tr>

              <tbody>
                <tr style="color: #00364f">
                  <td>
                    <img style="width: 30px; height: 30px" :src="adharFrontImg" alt="" />
                  </td>

                  <td><p>Aadhar Front</p></td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <button
                        @click="
                          extensionkycAdharFront.toLowerCase()== 'pdf'
                            ? openWindow(adharFrontImgPdf)
                            : openWindow(adharFrontImg)
                        "
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                        class="btn btns text-white btn-sm"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr style="color: #00364f">
                  <td>
                    <img style="width: 30px; height: 30px" :src="adharBackImg" alt="" />
                  </td>

                  <td>Aadhar Back</td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <button
                        @click="
                          extensionkycAdharBack.toLowerCase() == 'pdf'
                            ? openWindow(adharBackImgPdf)
                            : openWindow(adharBackImg)
                        "
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                        class="btn btns text-white btn-sm"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr style="color: #00364f">
                  <td>
                    <img style="width: 30px; height: 30px" :src="panImg" alt="" />
                  </td>

                  <td>
                    <p>Pan Card</p>
                  </td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <button
                        @click="
                          extensionkycPan.toLowerCase() == 'pdf'
                            ? openWindow(panImgPdf)
                            : openWindow(panImg)
                        "
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                        class="btn btns text-white btn-sm"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- </form> -->
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn text-white btn-sm"
            data-bs-dismiss="modal"
            style="background-color: #f21300"
          >
            Close
          </button>
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Profile Upadate  -->
  <div
    class="modal fade"
    id="profileUpdateModel"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel"><b>Profile Upadate</b></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                Profile
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Kyc
              </button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
            <small v-if="msgImgError">
        <div class="alert alert-danger p-1" role="alert" >
 {{msgImgError}}
</div>
       </small>
              <form @submit.prevent="profileUpdate()">
                <div class="row">
                  <div class="col-12 col-md-12 col-xl-12" style="padding: 5px 5px 5px 5px">
                    <div> 
                      <label for="profilePhoto">
                      
                      <img
                        style="width: 70px; height: 70px"
                        :src="proUpdatePhoto"
                        alt=""
                      />
                    </label>
                    </div>
                 
                   <input
  @change="uploadPhoto"
  id="profilePhoto"
  type="file"
  class="form-control"
  placeholder="Name"
  style="position: absolute; top: -150000000px;"
/>

                  </div>
                  <!-- <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                    <div>
                      <img
                        style="width: 100px; height: 100px"
                        :src="proUpdatelogo"
                        alt=""
                      />
                    </div>
                    <label>Logo</label>
                    <input
                      @change="uploadLogo"
                      type="file"
                      class="form-control"
                      id="profileLogo"
                      placeholder="Name"
                    />
                  </div> -->

                  <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                   


                    <label>Name <small style="color:#f21000">*</small></label>
                    <input
                    required
                      v-model="updateProfileForm.name"
                      type="text"
                      class="form-control"
                      placeholder="Name"
                    />
                    <small style="color: red" v-if="updateProfileForm.errors"
                            ><span v-if="updateProfileForm.errors.error"
                              ><span v-if="updateProfileForm.errors.error.name">{{
                                updateProfileForm.errors.error.name[0]
                              }}</span></span
                            ></small
                          >
                  </div>

                  <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                    <label>Email <small style="color:#f21000">*</small></label>
                    <input
                    required
                      type="email"
                      v-model="updateProfileForm.email"
                      class="form-control"
                      placeholder="Email"
                    />
                    <small style="color: red" v-if="updateProfileForm.errors"
                            ><span v-if="updateProfileForm.errors.error"
                              ><span v-if="updateProfileForm.errors.error.email">{{
                                updateProfileForm.errors.error.email[0]
                              }}</span></span
                            ></small
                          >
                  </div>

                  <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                    <label>Phone <small style="color:#f21000">*</small></label>
                    <input
                    required
                      type="number"
                      v-model="updateProfileForm.phone"
                      class="form-control"
                      placeholder="Phone"
                      @change="lenthheker($event, 'phonePro')"
                    />
                    <span style="color: #f21000" id="phonePro"></span>
                    <small style="color: red" v-if="updateProfileForm.errors"
                            ><span v-if="updateProfileForm.errors.error"
                              ><span v-if="updateProfileForm.errors.error.phone">{{
                                updateProfileForm.errors.error.phone[0]
                              }}</span></span
                            ></small
                          >
                  </div>
                  <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                    <label>Alt Phone</label>

                    <input
                      type="number"
                      v-model="updateProfileForm.alternativePhone"
                      class="form-control"
                      placeholder="Alt Phone"
                      @change="lenthheker($event, 'altPhonePro')"
                    />
                    <span style="color: #f21000" id="altPhonePro"></span>
                    <small style="color: red" v-if="updateProfileForm.errors"
                            ><span v-if="updateProfileForm.errors.error"
                              ><span v-if="updateProfileForm.errors.error.alternativePhone">{{
                                updateProfileForm.errors.error.alternativePhone[0]
                              }}</span></span
                            ></small
                          >
                  </div>

                  <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                    <label>DOB <small style="color:#f21000">*</small></label>
                    <input
                    required
                      type="date"
                      v-model="updateProfileForm.dob"
                      class="form-control"
                      placeholder="dob"
                    />
                    <small style="color: red" v-if="updateProfileForm.errors"
                            ><span v-if="updateProfileForm.errors.error"
                              ><span v-if="updateProfileForm.errors.error.dob">{{
                                updateProfileForm.errors.error.dob[0]
                              }}</span></span
                            ></small
                          >
                  </div>
                  <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                    <label>Gender <small style="color:#f21000">*</small></label>
                    <select
                    required
                      v-model="updateProfileForm.gender"
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option selected disabled>select Gender...</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                    <small style="color: red" v-if="updateProfileForm.errors"
                            ><span v-if="updateProfileForm.errors.error"
                              ><span v-if="updateProfileForm.errors.error.gender">{{
                                updateProfileForm.errors.error.gender[0]
                              }}</span></span
                            ></small
                          >
                  </div>

                  <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                    <label>State <small style="color:#f21000">*</small></label>
                    <select
                    required
                      v-model="updateProfileForm.state_id"
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option selected disabled>select...</option>
                      <option :value="sts.id" v-for="(sts, index) in states" :key="index">
                        {{ sts.name }}
                      </option>
                    </select>
                    <small style="color: red" v-if="updateProfileForm.errors"
                            ><span v-if="updateProfileForm.errors.error"
                              ><span v-if="updateProfileForm.errors.error.state_id">{{
                                updateProfileForm.errors.error.state_id[0]
                              }}</span></span
                            ></small
                          >
                  </div>

                  <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                    <label>City</label>
                    <input
                      v-model="updateProfileForm.city"
                      type="text"
                      class="form-control"
                      placeholder="City"
                    />
                    <small style="color: red" v-if="updateProfileForm.errors"
                            ><span v-if="updateProfileForm.errors.error"
                              ><span v-if="updateProfileForm.errors.error.city">{{
                                updateProfileForm.errors.error.city[0]
                              }}</span></span
                            ></small
                          >
                  </div>

                  <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                    <label>Pin <small style="color:#f21000">*</small></label>

                    <input
                    required
                      type="number"
                      v-model="updateProfileForm.pin"
                      class="form-control"
                      placeholder="Pin"
                      @change="lenthheker($event, 'pinCode')"
                    />
                    <span style="color: #f21000" id="pinCode"></span>
                    <small style="color: red" v-if="updateProfileForm.errors"
                            ><span v-if="updateProfileForm.errors.error"
                              ><span v-if="updateProfileForm.errors.error.pin">{{
                                updateProfileForm.errors.error.pin[0]
                              }}</span></span
                            ></small
                          >
                  </div>
                  <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                    <label>Password</label>

                    <input
                      type="text"
                      v-model="updateProfileForm.password"
                      class="form-control"
                      placeholder="password"
                      @change="lenthheker($event, 'proPassword')"
                    />
                    <span style="color: #f21000" id="proPassword"></span>
                    <small style="color: red" v-if="updateProfileForm.errors"
                            ><span v-if="updateProfileForm.errors.error"
                              ><span v-if="updateProfileForm.errors.error.password">{{
                                updateProfileForm.errors.error.password[0]
                              }}</span></span
                            ></small
                          >
                  </div>
                  <div
                    class="col-12 col-md-12 col-xl-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Address <small style="color:#f21000">*</small></label>
                    <textarea
                    required
                      class="form-control"
                      rows="3"
                      v-model="updateProfileForm.address"
                      placeholder="Address"
                    ></textarea>
                    <small style="color: red" v-if="updateProfileForm.errors"
                            ><span v-if="updateProfileForm.errors.error"
                              ><span v-if="updateProfileForm.errors.error.address">{{
                                updateProfileForm.errors.error.address[0]
                              }}</span></span
                            ></small
                          >
                  </div>
                </div>

                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-sm text-white"
                    data-bs-dismiss="modal"
                    style="background-color: #f21000"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    class="btn btn-sm text-white"
                    style="background-color: #00364f"
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
            <div
              class="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div class="row">
                <div class="col-12">
                  <div
                    class="alert alert-danger p-1 mt-1"
                    role="alert"
                    v-if="msgImgError"
                  >
                    {{ msgImgError }}
                  </div>
                </div>
              </div>

              <form
                @submit.prevent="
                  editKyc == 'fasle' ? profilekycCreate() : profilekycUpdate()
                "
              >
                <div class="container-fluid table-scroll">
                  <table class="table table-hover table-sm">
                    <tr class="text table-wrapper-scroll-y rounded-circle tr-head">
                      <th class="text-truncate">+</th>
                      <th class="text-truncate">Document</th>
                      <th class="text-truncate">Action</th>
                    </tr>

                    <tbody class="text">
                      <tr>
                        <td>
                          <img
                            style="width: 30px; height: 30px"
                            :src="adharFrontImg"
                            alt=""
                          />
                        </td>
                        <td class="text-truncate">
                          <strong class="text">Aadhaar Front</strong>
                          <input
                            type="number"
                            maxlength="12"
                            required
                            v-model="formKyc.aadharNo"
                            class="form-control"
                            placeholder="Adhar Number"
                            @change="lenthheker($event, 'professionalKycAddar')"
                          />
                          <span style="color: #f21000" id="professionalKycAddar"></span>
                          <span style="color: #f21000" id="clientKycAddar"></span>
                          <small style="color: red" v-if="formKyc.errors"
                            ><span v-if="formKyc.errors.error"
                              ><span v-if="formKyc.errors.error.aadharNo">{{
                                formKyc.errors.error.aadharNo[0]
                              }}</span></span
                            ></small
                          >
                        </td>
                        <td class="text-truncate">
                          <label for="AdharFront">
                            <img
                              style="width: 25px; height: 25px"
                              src="/assets/image/upload.png"
                            />
                          </label>
                          <input
                            @change="uploadAdharFront"
                            class="input-file"
                            style="position: absolute; top: -150000px"
                            id="AdharFront"
                            type="file"
                            accept="image/*"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            style="width: 30px; height: 30px"
                            :src="adharBackImg"
                            alt=""
                          />
                        </td>
                        <td class="text-truncate">
                          <strong class="text">Aadhaar Back</strong>
                        </td>
                        <td class="text-truncate">
                          <label for="AdharBack">
                            <img
                              style="width: 25px; height: 25px"
                              src="/assets/image/upload.png"
                            />
                          </label>
                          <input
                            @change="uploadAdharBack"
                            class="input-file"
                            style="position: absolute; top: -150000px"
                            id="AdharBack"
                            type="file"
                            accept="image/*"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img style="width: 30px; height: 30px" :src="panImg" alt="" />
                        </td>
                        <td class="text-truncate">
                          <strong class="text">Pan</strong>
                          <input
                            v-if="panVerify == 'true'"
                            maxlength="10"
                            type="tel"
                            v-model="formKyc.panNo"
                            class="form-control"
                            placeholder="Pan Number"
                            disabled
                          />
                          <input
                            v-else
                            maxlength="10"
                            type="tel"
                            v-model="formKyc.panNo"
                            @change="panVeryfied()"
                            @keydown="nameKeydown($event)"
                            class="form-control"
                            placeholder="Pan Number"
                          />
                          <span style="color: #f21000" id="adminPanVeryfied"></span>
                          <small style="color: red" v-if="formKyc.errors"
                            ><span v-if="formKyc.errors.error"
                              ><span v-if="formKyc.errors.error.panNo">{{
                                formKyc.errors.error.panNo[0]
                              }}</span></span
                            ></small
                          >
                        </td>
                        <td class="text-truncate">
                          <label for="panphoto">
                            <img
                              style="width: 25px; height: 25px"
                              src="/assets/image/upload.png"
                            />
                          </label>
                          <input
                            @change="uploadPanPhoto"
                            class="input-file"
                            style="position: absolute; top: -150000px"
                            id="panphoto"
                            type="file"
                            accept="image/*"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-sm text-white"
                    style="background-color: #f21300"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    class="btn text-white btn-sm"
                    style="background-color: #00364f"
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import moment from "moment";
import Banner from "../../../components/retailer/comman/Banner.vue";
import Spinner from "../../../components/staff/comman/Spinner.vue";
// import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "Staff Profile",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      loading: false,
      adharFrontImg: this.$store.state.placeholderImg,
      adharBackImg: this.$store.state.placeholderImg,
      panImg: this.$store.state.placeholderImg,
      passBookImg: this.$store.state.placeholderImg,
      profileImg: this.$store.state.placeholderImg,

      staffinfo: {},
      staffkyc: {},
      staff_services: [],

      extensionkycProfilePhoto: "",
      extensionkycProfileLogo: "",
      proUpdatePhoto: this.$store.state.placeholderImg,
      proUpdatelogo: this.$store.state.placeholderImg,
      updateProfileForm: {
        address: "",
        alternativePhone: "",
        city: "",
        dob: "",
        email: "",
        gender: "",
        ipAddress: "",
        logo: "",
        photo: "",
        name: "",
        phone: "",
        state_id: null,
        password: "",
        pin: "",
        errors:{}
      },
      msgImgError: "",
      editKyc: "fasle",
      formKyc: {
        panNo: null,
        panPhoto: "",
        accountType: "",
        accountNo: null,
        passbookPhoto: "",
        aadharNo: null,
        aadharFrontPhoto: "",
        aadharBackPhoto: "",
        remark: "",
        errors: {},
      },
      states: [],
      panVerify: "fasle",
      submitStatus: "fasle",
      professionalKycAddar: "fasle",
      proPassword: "fasle",
      pinCode: "fasle",
      altPhonePro: "fasle",
      phonePro: "fasle",
    };
  },
  methods: {
    lenthheker(event, type) {
      var data = event.target.value;
    //       var forWallet = parseInt(data)
    // var  amount  = parseInt(this.wallet.balanceAmount)

    //   if (type == "walletAmount") {
    //     if (forWallet > amount) {
    //       this.walletAmount = "true";

    //       $("#walletAmount").html("<small> You Have Unsefisian Balance</small>");
    //     }else{ 
    //        this.walletAmount = "false";
    //       $("#walletAmount").html("<small></small>");
    //     }
    //   }

      if (type == "dobDate") {

        var currentDate = new Date();
        data = this.dateTime(data)
        currentDate = this.dateTime(currentDate);
        if (data > currentDate) {
          this.dobDate = "true";

          $("#dobDate").html("<small> Enter Valid Date</small>");
        }else{ 
           this.dobDate = "false";
          $("#dobDate").html("<small></small>");
        }
      }





      if (type == "phonePro") {
        if (data.length != 10) {
          this.phonePro = "true";
          $("#phonePro").html("<small>Phone Number 10 Digit Required</small>");
        }
        if (data.length == 10) {
          this.phonePro = "false";
          $("#phonePro").html("<small></small>");
        }
      }
      if (type == "altPhonePro") {
        if (data.length != 10) {
          if(data.length == 0){
            this.altPhonePro = "false";
          $("#altPhonePro").html("<small></small>");
          }else{
            this.altPhonePro = "true";
          $("#altPhonePro").html("<small>Alt Phone Number 10 Digit Required</small>");
          }
       
        }
        if (data.length == 10) {
          this.altPhonePro = "false";
          $("#altPhonePro").html("<small></small>");
        }
      }




      if (type == "pinCode") {
        if (data.length != 6) {
          this.pinCode = "true";
          $("#pinCode").html("<small>Pin Code 6 Digit Required</small>");
        }
        if (data.length == 6) {
          this.pinCode = "false";
          $("#pinCode").html("<small></small>");
        }
      }


      if (type == "proPassword") {
        if (data.length < 6) {
        
          if(data.length == 0){
            this.proPassword = "false";
          $("#proPassword").html("<small></small>");
          }else{
            this.proPassword = "true";
          $("#proPassword").html("<small>Password Minimum 6 Digits Required</small>");


          }
       
       
        }
        if (data.length >= 6) {
          this.proPassword = "false";
          $("#proPassword").html("<small></small>");
        }
      }





      if (type == "gstinVal1") {
        
        if (data.length != 15) { 

          if (data.length == 0) {
          this.gstinVal1 = "false";
          $("#gstinVal1").html("<small></small>");
        }else{
          this.gstinVal1 = "true";
          $("#gstinVal1").html("<small>GSTIN 15 Digit Required</small>");
        }
        }
        if (data.length == 15) {
          this.gstinVal1 = "false";
          $("#gstinVal1").html("<small></small>");
        }
      }

      if (type == "cinVal") {
        
        if (data.length != 21) {  if (data.length == 0) {
          this.cinVal = "false";
          $("#cinVal").html("<small></small>");
        }else{
          this.cinVal = "true";
          $("#cinVal").html("<small>CIN 21 Digit Required</small>");
        }
        }
        if (data.length == 21) {
          this.cinVal = "false";
          $("#cinVal").html("<small></small>");
        }
      }


      if (type == "bussinessPinCode") {
        if (data.length != 6) {
          this.bussinessPinCode = "true";
          $("#bussinessPinCode").html("<small>Pin Code 6 Digit Required</small>");
        }
        if (data.length == 6) {
          this.bussinessPinCode = "false";
          $("#bussinessPinCode").html("<small></small>");
        }
      }

            // kyc 

      if (type == "professionalKycAddar") {
        if (data.length != 12) {
          this.professionalKycAddar = "true";

          $("#professionalKycAddar").html(
            "<small>Aadhar required  12 Digits</small>"
          );
        }
        if (data.length == 12) {
          this.professionalKycAddar = "false";
          $("#professionalKycAddar").html("<small></small>");
        }
      }





       // benificiary 

    },
    panVeryfied() {
      var data = this.formKyc.panNo;
      if (data.length == 10) {
        this.submitStatus = "false";
        $("#adminPanVeryfied").html("<small></small>");
        // this.msgPanError = "";
        this.loading = true;
        this.$axios
          .post(
            "staff/verifypan",
            { panNo: this.formKyc.panNo },
            { headers: { Authorization: "Bearer " + localStorage.accessToken } }
          )
          .then((res) => {
            if (res.data.data.message) {
              // this.msgPanError = res.data.data.message;
              this.submitStatus = "true";
              $("#adminPanVeryfied").html("<small>" + res.data.data.message + "</small>");
            }
            if (res.data.data.data) {
              $("#adminPanVeryfied").html(
                "<small>" + res.data.data.data.status + " Pan </small>"
              );
            }
            if (res.data.data.data.full_name) {
              this.submitStatus = "false";
              $("#adminPanVeryfied").html("<small></small>");
              this.panVerify = "true";
              toast.success("Pan Verified Succesfull", {
                autoClose: 1000,
              });
            }
          }) .finally(() => (this.loading = false))

          .catch((error) => {
            this.submitStatus = "true";
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
          });
      } else {
        this.submitStatus == "true";
        $("#adminPanVeryfied").html("<small> Pan required 10 Digits</small>");
      }

      // this.panNo = ''
    },
    nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        $("#adminPanVeryfied").html("<small>special characters are not allowed</small>");
        e.preventDefault();
      } else {
        $("#adminPanVeryfied").html("<small></small>");
      }
    },
    uploadAdharFront(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Aadhar Front File Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#AdharFront").value;
        this.extensionkycAdharFront = fileName.split(".").pop();

        if (
          this.extensionkycAdharFront == "pdf" ||
          this.extensionkycAdharFront == "jpg" ||
          this.extensionkycAdharFront == "jpeg" ||
          this.extensionkycAdharFront == "png" || 

          this.extensionkycAdharFront == "PDF" ||
          this.extensionkycAdharFront == "JPG" ||
          this.extensionkycAdharFront == "JPEG" ||
          this.extensionkycAdharFront == "PNG"
        ) {
          this.adharFrontImg = URL.createObjectURL(e.target.files["0"]);
          this.formKyc.aadharFrontPhoto = e.target.files["0"];
        } else {
          this.msgImgError = "File type must JPG ,JPEG , PNG , PDF ";
        }

       
        if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
          this.adharFrontImg = "/assets/image/pdf.png";
        }
      }
    },
    uploadAdharBack(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Aadhar Back  File Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#AdharBack").value;
        this.extensionkycAdharBack = fileName.split(".").pop();

        if (
          this.extensionkycAdharBack == "pdf" ||
          this.extensionkycAdharBack == "jpg" ||
          this.extensionkycAdharBack == "jpeg" ||
          this.extensionkycAdharBack == "png" || 

          this.extensionkycAdharBack == "PDF" ||
          this.extensionkycAdharBack == "JPG" ||
          this.extensionkycAdharBack == "JPEG" ||
          this.extensionkycAdharBack == "PNG"
        ) {
          this.adharBackImg = URL.createObjectURL(e.target.files["0"]);
          this.formKyc.aadharBackPhoto = e.target.files["0"];
        } else {
          this.msgImgError = "File type must JPG , PNG , PDF, JPEG ";
        }

       
        if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
          this.adharBackImg = "/assets/image/pdf.png";
        }
      }
    },

    uploadPanPhoto(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Pan File Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#panphoto").value;
        this.extensionkycPan = fileName.split(".").pop();

        if (
           this.extensionkycPan == "pdf" ||
          this.extensionkycPan == "jpg" ||
          this.extensionkycPan == "jpeg" ||
          this.extensionkycPan == "png" || 

          this.extensionkycPan == "PDF" ||
          this.extensionkycPan == "JPG" ||
          this.extensionkycPan == "JPEG" ||
          this.extensionkycPan == "PNG"
        ) {
          this.panImg = URL.createObjectURL(e.target.files["0"]);
          this.formKyc.panPhoto = e.target.files["0"];
        } else {
          this.msgImgError = "File type must JPG , PNG , PDF, JPEG ";
        }

       
        if (this.extensionkycPan == "pdf" || this.extensionkycPan == "PDF") {
          this.panImg = "/assets/image/pdf.png";
        }
      }
    },

    valueAssingForProfile() {
      this.panVerify = "false";
      this.msgImgError = "";
     this.updateProfileForm.errors = {}
      this.submitStatus = "false";
        $("#adminPanVeryfied").html("<small></small>");

        this.professionalKycAddar = "false";
          $("#professionalKycAddar").html("<small></small>");


          this.proPassword = "false";
          $("#proPassword").html("<small></small>");

          this.pinCode = "false";
          $("#pinCode").html("<small></small>");
          this.altPhonePro = "false";
          $("#altPhonePro").html("<small></small>");

          this.phonePro = "false";
          $("#phonePro").html("<small></small>");



      this.formKyc = {}
      this.formKyc.errors = {}
      this.updateProfileForm = {}
      this.updateProfileForm.errors = {}

      this.updateProfileForm.name = this.staffinfo.name;
      this.updateProfileForm.phone = this.staffinfo.phone;
      this.updateProfileForm.address = this.staffinfo.address;
      this.updateProfileForm.alternativePhone = this.staffinfo.alternativePhone;
      this.updateProfileForm.city = this.staffinfo.city;
      this.updateProfileForm.email = this.staffinfo.email;
      this.updateProfileForm.gender = this.staffinfo.gender;
      this.updateProfileForm.state_id = this.staffinfo.state_id;
      this.updateProfileForm.pin = this.staffinfo.pin;
      this.updateProfileForm.dob = this.staffinfo.dob;
      if (this.staffinfo.logo) {
        this.proUpdatelogo =
          this.$store.state.imgUrl +
          "/staff/" +
          this.staffinfo.id +
          "/thumbs/" +
          this.staffinfo.logo;
      } else {
        this.proUpdatelogo = this.$store.state.placeholderImg;
      }

      if (this.staffinfo.photo) {
        this.proUpdatePhoto =
          this.$store.state.imgUrl +
          "/staff/" +
          this.staffinfo.id +
          "/thumbs/" +
          this.staffinfo.photo;
      } else {
        this.proUpdatePhoto = this.$store.state.placeholderImg;
      }

      // kyc

      this.adharFrontImg = this.$store.state.placeholderImg;
      this.adharBackImg = this.$store.state.placeholderImg;
      this.panImg = this.$store.state.placeholderImg;

      if (this.staffinfo.staff_kyc) {
        this.formKyc.panNo = this.staffinfo.staff_kyc.panNo;
        if (this.staffinfo.staff_kyc.panNo) {
          this.panVerify = "true";
        }

        this.editKyc = "true";

        this.formKyc.aadharNo = this.staffinfo.staff_kyc.aadharNo;

        if (this.staffinfo.staff_kyc.panPhoto != null) {
        
          if (
            this.$store.state.imgUrl +
            "/staff/" +
            this.staffinfo.id +
            "/thumbs/" +
            this.staffinfo.staff_kyc.panPhoto
          ) {
            this.panImg =
              this.$store.state.imgUrl +
              "/staff/" +
              this.staffinfo.id +
              "/thumbs/" +
              this.staffinfo.staff_kyc.panPhoto;
          }

          if (
            this.$store.state.imgUrl +
            "/staff/" +
            this.staffinfo.id +
            "/" +
            this.staffinfo.staff_kyc.panPhoto
          ) {
            this.panImg =
              this.$store.state.imgUrl +
              "/staff/" +
              this.staffinfo.id +
              "/" +
              this.staffinfo.staff_kyc.panPhoto;
          }
          this.panImgPdf = this.panImg
          this.extensionkycPan = this.panImg.split(".").pop();

          if (this.extensionkycPan == "pdf" || this.extensionkycPan == "PDF") {
            this.panImg = "/assets/image/pdf.png";
          }
        } else {
          this.panImg = this.$store.state.placeholderImg;
        }

        // aadhar front

        if (this.staffinfo.staff_kyc.aadharFrontPhoto != null) {
          if (
            this.$store.state.imgUrl +
            "/staff/" +
            this.staffinfo.id +
            "/thumbs/"+
            this.staffinfo.staff_kyc.aadharFrontPhoto
          ) {
            this.adharFrontImg =
              this.$store.state.imgUrl +
              "/staff/" +
              this.staffinfo.id +
              "/thumbs/" +
              this.staffinfo.staff_kyc.aadharFrontPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/staff/" +
            this.staffinfo.id +
            "/" +
            this.staffinfo.staff_kyc.aadharFrontPhoto
          ) {
            this.adharFrontImg =
              this.$store.state.imgUrl +
              "/staff/" +
              this.staffinfo.id +
              "/" +
              this.staffinfo.staff_kyc.aadharFrontPhoto;
          }

          this.adharFrontImgPdf = this.adharFrontImg
          this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

          if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
            this.adharFrontImg = "/assets/image/pdf.png";
          }
        } else {
          this.adharFrontImg = this.$store.state.placeholderImg;
        }

        
        // aadhar Back photo

        if (this.staffinfo.staff_kyc.aadharBackPhoto != null) {
          if (
            this.$store.state.imgUrl +
            "/staff/" +
            this.staffinfo.id +
            "/thumbs/"+
            this.staffinfo.staff_kyc.aadharBackPhoto
          ) {
            this.adharBackImg =
              this.$store.state.imgUrl +
              "/staff/" +
              this.staffinfo.id +
              "/thumbs/" +
              this.staffinfo.staff_kyc.aadharBackPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/staff/" +
            this.staffinfo.id +
            "/" +
            this.staffinfo.staff_kyc.aadharBackPhoto
          ) {
            this.adharBackImg =
              this.$store.state.imgUrl +
              "/staff/" +
              this.staffinfo.id +
              "/" +
              this.staffinfo.staff_kyc.aadharBackPhoto;
          }

          this.adharBackImgPdf = this.adharBackImg
          this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

          if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
            this.adharBackImg = "/assets/image/pdf.png";
          }
        } else {
          this.adharBackImg = this.$store.state.placeholderImg;
        }


     
      }
    },
    uploadPhoto(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Profile Photo Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#profilePhoto").value;
        this.extensionkycProfilePhoto = fileName.split(".").pop();

        if (
          this.extensionkycProfilePhoto == "jpg" ||
          this.extensionkycProfilePhoto == "jpeg" ||
          this.extensionkycProfilePhoto == "png"  ||
          this.extensionkycProfilePhoto == "JPG" ||
          this.extensionkycProfilePhoto == "JPEG" ||
          this.extensionkycProfilePhoto == "PNG"
        ) {
          this.proUpdatePhoto = URL.createObjectURL(e.target.files["0"]);
          this.updateProfileForm.photo = e.target.files["0"];
        } else {
          this.msgImgError = "File type must JPG ,JPEG, PNG  ";
        }
      }
    },

    uploadLogo(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Logo Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#profileLogo").value;
        this.extensionkycProfileLogo = fileName.split(".").pop();

        if (
          this.extensionkycProfileLogo == "jpg" ||
          this.extensionkycProfileLogo == "jpeg" ||
          this.extensionkycProfileLogo == "png"
        ) {
          this.proUpdatelogo = URL.createObjectURL(e.target.files["0"]);
          this.updateProfileForm.logo = e.target.files["0"];
        } else {
          this.msgImgError = "File type must JPG ,JPEG, PNG  ";
        }
      }
    },
    loadState() {
      this.$axios
        .get("staff/state?all=all", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data)
          this.states = res.data.data;
          // console.log(this.states)
        });
    },
    profileUpdate() {
      if(this.phonePro == 'false' &&
       this.altPhonePro == 'false' && 
       this.pinCode == 'false' &&
       this.msgImgError == '' &&
        this.proPassword == 'false' ){


          const formData = new FormData();
      const keys = Object.keys(this.updateProfileForm);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.updateProfileForm[keys[n]] !== null) {
          formData.append(keys[n], this.updateProfileForm[keys[n]]);
        }
      }

      this.loading = true;
      this.$axios
        .post(`staff/staff`, formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          toast.success("Updated Succesfully", {
            autoClose: 1000,
          });
          
          $("#profileUpdateModel").modal("hide");
          this.updateProfileForm = {};
          this.editKyc = "false";
          window.location.reload();
          this.loadMasterDistributer();
          
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
           this.updateProfileForm.errors = error.response.data;
          });
      }
   
    },

    profilekycUpdate() {
      if(this.submitStatus == 'false' && this.professionalKycAddar == 'false'){
        const formData = new FormData();
      const keys = Object.keys(this.formKyc);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.formKyc[keys[n]] !== null) {
          formData.append(keys[n], this.formKyc[keys[n]]);
        }
      }

      var kycId = null;
      if (this.staffinfo.staff_kyc) {
        kycId = this.staffinfo.staff_kyc.id;
      }
      this.loading = true;
      this.$axios
        .post(`staff/staffkyc/${kycId}?_method=PUT`, formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          toast.success("Updated Succesfully", {
            autoClose: 1000,
          });
          $("#profileUpdateModel").modal("hide");
          this.editKyc = "false";
          this.formKyc = {};
          this.loadStaff();
          // window.location.reload();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
           this.formKyc.errors = error.response.data;
          });
      }
    
        
    },

    profilekycCreate() {
      if(this.submitStatus == 'false' && this.professionalKycAddar == 'false'){
        const formData = new FormData();
      const keys = Object.keys(this.formKyc);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.formKyc[keys[n]] !== null) {
          formData.append(keys[n], this.formKyc[keys[n]]);
        }
      }

      this.loading = true;
      this.$axios
        .post(`staff/staffkyc`, formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          toast.success("Created Succesfully", {
            autoClose: 1000,
          });
          $("#profileUpdateModel").modal("hide");
          this.formKyc = {};
          this.loadStaff();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
           this.formKyc.errors = error.response.data;
          });
      }
    
    },

    // end profile update

    openWindow(url) {
      window.open(`${url}`);
    },
    dateTime(value) {
      console.log(value);
       if(value){
  return moment(value).format("DD-MM-YYYY");
      }
    
    },
    dateTimeWithTime(value) {
      console.log(value);
      if(value){
return moment(value).format("DD-MM-YYYY,  HH:mm ");
      }
      
    },
    kycDoccLoad() {
      if (this.staffinfo.staff_kyc.panPhoto != null) {
        this.panImg =
          this.$store.state.imgUrl +
          "/staff/" +
          this.staffinfo.id +
          "/thumbs/" +
          this.staffinfo.staff_kyc.panPhoto;
      } else {
        this.panImg = this.$store.state.placeholderImg;
      }

      if (this.staffinfo.staff_kyc.passbookPhoto != null) {
        this.passBookImg =
          this.$store.state.imgUrl +
          "/staff/" +
          this.staffinfo.id +
          "/thumbs/" +
          this.staffinfo.staff_kyc.passbookPhoto;
      } else {
        this.passBookImg = this.$store.state.placeholderImg;
      }

      if (this.staffinfo.staff_kyc.aadharFrontPhoto != null) {
        this.adharFrontImg =
          this.$store.state.imgUrl +
          "/staff/" +
          this.staffinfo.id +
          "/thumbs/" +
          this.staffinfo.staff_kyc.aadharFrontPhoto;
      } else {
        this.adharFrontImg = this.$store.state.placeholderImg;
      }

      if (this.staffinfo.staff_kyc.aadharBackPhoto != null) {
        this.adharBackImg =
          this.$store.state.imgUrl +
          "/staff/" +
          this.staffinfo.id +
          "/thumbs/" +
          this.staffinfo.staff_kyc.aadharBackPhoto;
      } else {
        this.adharBackImg = this.$store.state.placeholderImg;
      }
    },
    loadStaff() {
      this.loading = true;
      this.$axios
        .post(
          `staff/user`,
          {},
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          this.staffinfo = res.data.user;
          console.log(this.staffinfo);

          if (this.staffinfo.photo != null) {
            this.profileImg =
              this.$store.state.imgUrl +
              "/staff/" +
              this.staffinfo.id +
              "/thumbs/" +
              this.staffinfo.photo;
          } else {
            this.profileImg = this.$store.state.placeholderImg;
          }
          this.kycDoccLoad();
        })
        .finally(() => (this.loading = false));
    },
    loadPageTitle(){
        document.title =this.$store.state.staffPageTitles.staffView
      }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadStaff();
      this.loadState();
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}


.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
</style>
